<template>
  <div>
    <meta-actions-header class="mb-12" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700"
          ><span>: </span><span v-if="publisher.id" class="text-orange-500">{{ publisher.name }}</span>
        </breadcrumb>
      </template>
    </meta-actions-header>
    <tabs :tabs-data="tabs" :base-publisher="publisher" :clients="clients"></tabs>
  </div>
</template>

<script>
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import Tabs from '@/components/atoms/Tabs';
import CONFIG from './config';
import Tab from '@/model/shared/Tab';
import apiRequest from '@/utils/apiRequest';
import getPublisherById from '@/services/modules/ecommerce/publisher/getPublisherById';
import { Toast } from '@/model/shared/Toast';
import Publisher from '@/entities/ecommerce/Publisher';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getClients } from '@/services/modules/Core/client';
import { CONTEXTS } from '@/model/shared/contexts';

export default {
  name: 'PublisherTabs',
  components: {
    MetaActionsHeader,
    Breadcrumb,
    Tabs,
  },
  data: () => ({
    isLoading: false,
    publisher: null,
    clients: null,
    tabs: [],
  }),
  computed: {
    publisherId() {
      return this.$route.params.publisherId;
    },
    isEdit() {
      return !!this.publisherId;
    },
  },
  async created() {
    this.isLoading = true;
    this.configTabs();
    await Promise.all([this.getPublisher(), this.getClients()]);
    this.isLoading = false;
  },
  methods: {
    configTabs() {
      const routes = this.isEdit ? CONFIG.tabRouteEdit : CONFIG.tabRouteCreate;
      this.tabs = Object.entries(CONFIG.tabNames).map(([key, name]) => {
        const tab = new Tab(name, routes[key]);
        if (name === 'Users') {
          const params = new QueryParamsBuilder();
          params.addFilter('contextRole.role', 'PUBLISHER');
          params.addFilter('userPublisherLink.publisherId', this.publisherId);
          const query = params.buildWithoutPage();
          tab.route.query = query;
        }
        return tab;
      });
    },
    async getPublisher() {
      if (this.isEdit) {
        await apiRequest(async () => {
          const { data } = await getPublisherById(this.publisherId);
          this.publisher = data;
        }).catch(error => {
          this.createToast(Toast.error(`Failed to load the publisher`, error.message));
        });
      } else {
        this.publisher = new Publisher();
      }
    },
    async getClients() {
      await apiRequest(async () => {
        const params = new QueryParamsBuilder(1, 1000);
        params.addFilter('type', CONTEXTS.ECOMMERCE.id);
        const { data } = await getClients(params);
        this.clients = data;
      }).catch(error => {
        this.createToast(Toast.error(`Failed to load clients`, error.message));
      });
    },
  },
};
</script>
