import basicInfo from '@/router/private/modules/ecommerce/supply/publisher/edit/basicInfo';
import { default as users } from '@/router/private/modules/ecommerce/admin/users/list';
import { default as newBasicInfo } from '@/router/private/modules/ecommerce/supply/publisher/create/basicInfo';

const tabNames = {
  basic: 'Basic Info',
  users: 'Users',
};

const tabRouteEdit = {
  basic: basicInfo,
  users,
};

const tabRouteCreate = {
  basic: newBasicInfo,
  users,
};

export default Object.freeze({ tabNames, tabRouteEdit, tabRouteCreate });
