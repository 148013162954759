var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("meta-actions-header", {
        staticClass: "mb-12",
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "breadcrumb",
                        { staticClass: "flex flex-1 text-gray-700" },
                        [
                          _c("span", [_vm._v(": ")]),
                          _vm.publisher.id
                            ? _c("span", { staticClass: "text-orange-500" }, [
                                _vm._v(_vm._s(_vm.publisher.name)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("tabs", {
        attrs: {
          "tabs-data": _vm.tabs,
          "base-publisher": _vm.publisher,
          clients: _vm.clients,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }